<template>
    <div>
        <banner-section-component/>
<!--        <slide-section/>-->
        <slogan-section/>
        <about-section/>
        <mision-section/>
        <vision-section/>
        <values-section/>
<!--        <info-section/>-->
        <categories-section/>
<!--        <projects-section/>-->
        <paralax-company-section/>
        <contact-section/>
    </div>
</template>

<script>
    import BannerSectionComponent from "@/components/BannerSectionComponent.vue";
    import AboutSection from "@/components/AboutSection";
    // import ProjectsSection from "@/components/ProjectsSection";
    import ContactSection from "@/components/ContactSection";
    import SloganSection from "@/components/SloganSection";
    import MisionSection from "@/components/MisionSection";
    import VisionSection from "@/components/VisionSection";
    import ValuesSection from "@/components/ValuesSection";
    // import InfoSection from "../components/InfoSection";
    import ParalaxCompanySection from "../components/ParalaxCompanySection";
    import CategoriesSection from "@/components/CategoriesSection";
    // import SlideSection from "@/components/SlideSection";

    export default {
        name: "HomePage",
        title: "Private Inmobiliaria",
        components: {
            CategoriesSection,
            ParalaxCompanySection,
            // InfoSection,
            ValuesSection,
            VisionSection,
            MisionSection,
            // SlideSection,
            SloganSection,
            ContactSection,
            // ProjectsSection,
            AboutSection,
            BannerSectionComponent
        }
    };
</script>
