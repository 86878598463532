var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sectionItem)?_c('section',{attrs:{"id":"slogan"}},[(_vm.sectionItem.image_url !== null)?_c('v-container',{staticClass:"fill-height ma-0 pa-0 text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-img',{staticClass:"mx-0 px-0 align-center",attrs:{"src":_vm.sectionItem.image_url,"width":"100%","height":"350","max-height":"400","position":"top","transition":"slide-x-transition","gradient":"to top, rgba(0,0,0,.3), rgba(0,0,0,.5)"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
                            'animation-name': 'slideInDown',
                            'animation-duration': '1s',
                            'animation-delay': '2s'
                        }),expression:"{\n                            'animation-name': 'slideInDown',\n                            'animation-duration': '1s',\n                            'animation-delay': '2s'\n                        }"}],staticClass:"holder"},[_c('span',{staticClass:"text-h5 text-md-h3 white--text text-uppercase text-light font-weight-light"},[_vm._v(" "+_vm._s(_vm.sectionItem.title)+" ")])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-0 wow animate__animated animate__slideInUp animate__slow animate__delay-2s",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"text-caption"},[_c('p',{staticClass:"text-h6 text-md-h4 font-weight-light orange--text text--lighten-3 text-uppercase"},[_vm._v(" "+_vm._s(_vm.sectionItem.sub_title)+" ")])])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ma-2 yellow--text text--lighten-3",attrs:{"outlined":"","large":"","dark":"","plain":""},nativeOn:{"click":function($event){return _vm.scrollToId('contact')}}},[_vm._v(" Contáctenos ")])],1)],1)],1)],1)],1):_c('v-container',{staticClass:"fill-height py-10 text-center",staticStyle:{"width":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
                    'animation-name': 'slideInDown',
                    'animation-duration': '1s',
                    'animation-delay': '2s'
                }),expression:"{\n                    'animation-name': 'slideInDown',\n                    'animation-duration': '1s',\n                    'animation-delay': '2s'\n                }"}],staticClass:"holder"},[_c('span',{staticClass:"text-h3 text-md-h2 blue--text text-uppercase text-light font-weight-light"},[_vm._v(" Inversiones para un futuro asegurado! ")])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-0 wow animate__animated animate__slideInUp animate__slow animate__delay-2s",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"text-caption"},[_c('p',{staticClass:"text-h5 text-md-h4 font-weight-light black--text text--lighten-3 text-uppercase"},[_vm._v(" Empiece a invertir con nosotros ")])])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ma-2 yellow--text text--lighten-3",attrs:{"outlined":"","large":"","dark":"","plain":""},nativeOn:{"click":function($event){return _vm.scrollToId('contact')}}},[_vm._v(" Contáctenos ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }