<template>
    <section id="categories">
        <v-container fluid class="container--fluid mb-0">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <v-container flui class="fill-height">
                        <v-row dense no-gutters class="mb-5">
                            <v-col cols="12" class="text-start justify-start">
                                <v-row align="end">
                                    <v-col>
                                        <span
                                            class="font-weight-light display-2 text-h5 text-md-h3 grey--text text--darken-3">
                                            Categorías
                                        </span>
                                    </v-col>
                                    <v-col cols="2" class="justify-end text-end">
                                        <v-btn
                                            color="primary"
                                            x-small
                                            rounded
                                            class="text-capitalize"
                                            text
                                            @click="showAllCategories()">
                                            Ver todas
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-1"/>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col v-for="item in categories"
                                   :key="item.id"
                                   cols="12" md="4"
                                   class="px-0 px-md-3">
                                <v-card
                                    class="border-info"
                                    rounded="lg"
                                    elevation="3"
                                    >
                                    <div class="text-center">
                                        <v-img
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            :src="item.image_url != null ? item.image_url : require('@/assets/images/no-image.jpg')"
                                            class="rounded-t-lg"
                                            height="300"
                                            max-width="100%"
                                            position="top">
                                            <template v-slot:placeholder>
                                                <v-row
                                                    align="center"
                                                    class="fill-height ma-0"
                                                    justify="center">
                                                    <v-progress-circular
                                                        color="grey lighten-5"
                                                        indeterminate>
                                                    </v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </div>
                                    <v-card-title class="mt-3 text-subtitle-1 font-weight-light">
                                        {{ item.name }}
                                    </v-card-title>
                                    <v-card-text>
                                        <!-- long paragraph -->
                                        <div class="caption black--text">
                                            {{ item.description }}
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                        <v-btn link text color="blue" small @click="showCategory(item.id)">
                                            Conoce más >
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="ma-10" v-if="categories.length === 0">
                            <div class="text-subtitle-1 text-md-h4">
                                No hay Categorías en el catálogo...
                            </div>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>


<script>
    import categoriesService from "@/providers/CategoriesService";
    // import projectsService from '@/providers/ProjectsService';

    export default {
        name: "CategoriesSection",
        data: () => ({
            model: null,
            categories: [],
            projectsList: []
        }),
        methods: {
            getCategories() {
                // Lista de Categorías
                categoriesService.listCategories().then(record => {
                    this.categories = record.value;
                    console.log('categorias: ', this.investors);
                });
            },
            showAllCategories() {
                this.$router.push({path: '/categories'});
            },
            showCategory(category_id) {
                this.$router.push({
                    name: "CategoryProfile",
                    params: {
                        id: category_id
                    }
                });
            }
        },
        mounted() {
            this.categories = [];
            this.projectsList = [];
            this.getCategories();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
    img {
        max-width: 100%;
    }
    section {
        position: relative;
        background-color: #ffffff;
        margin-top: 0px;
        padding-top: 10px;
    }
    .backcolor {
        background-color: #151C4C;
        /*background-color: #f4f7f5;*/
    }
    .boxdivider {
        background-color: transparent;
        width: 100%;
        height: 1px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 1px;
        /*border-bottom-color: blueviolet;*/
        border-bottom-color: #abdde5;
        border-bottom-style: solid;
        display: block;
    }
    .card {
        min-height: 150px;
        border-width: 0px;
        /*padding: 5px;*/
        transition: 0.5s ease-out;
    }
    .card .v-image {
        /*margin-bottom: 5px;*/
        transition: 0.75s;
    }
    .card h1 {
        margin-bottom: 10px;
    }
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        align-content: end;
        justify-content: center;
        opacity: .7;
        position: relative;
        width: 100%;
    }
    .zoom-efect {
        transform: scale(1.1);
    }
    .up {
        transform: translateY(-20px);
        transition: 0.5s ease-out;
    }
</style>