<template>
    <section id="contact">
        <v-container fluid class="pb-10 mt-0">
            <v-row align="start" justify="center">
                <v-col cols="10">
                    <v-row align="start" justify="center">
                        <v-col cols="12" class="text-center justify-center">
                            <p class="font-weight-light display-2 mb-3 text-h4 text-md-h3 grey--text text--darken-3">
                                Contáctenos
                            </p>
                            <v-row justify="center">
                                <div class="boxdivider"></div>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <span class="font-weight-light text-h6 text-md-h5">Datos del contacto</span>
                            <h4 class="font-weight-light mt-1">
                                <table cellpadding="3" cellspacing="3">
                                    <tr>
                                        <td><v-icon color="orange" class="text--darken-1" small>mdi-email</v-icon></td>
                                        <td><span class="text-subtitle-2">Email: {{ company.email }}</span></td>
                                    </tr>
                                    <!--<tr>
                                        <td><v-icon color="orange" class="text&#45;&#45;darken-1">mdi-phone</v-icon></td>
                                        <td>
                                            Teléfono: {{ company.phone1 | formatPhone('MX') }}, {{ company.phone2 | formatPhone('US') }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><v-icon color="orange" class="text&#45;&#45;darken-1">mdi-skype</v-icon></td>
                                        <td>Skype: {{ company.skype_profile }}</td>
                                    </tr>-->
                                    <tr>
                                        <td><v-icon color="orange" class="text--darken-1" small>mdi-map-marker</v-icon></td>
                                        <td><span class="text-subtitle-2">Dirección: {{ company.address }}</span></td>
                                    </tr>
                                </table>
                            </h4>
                            <v-container class="mt-auto mx-auto" fluid>
                                <div :id="containerID" :style="containerCSS"></div>
                            </v-container>
                        </v-col>
                        <v-col cols="12" sm="7">
                            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                                <v-text-field
                                    dense
                                    v-model="contactItem.name"
                                    :rules="nameRules"
                                    label="Nombre"
                                    required>
                                </v-text-field>
                                <v-text-field
                                    dense
                                    v-model="contactItem.email"
                                    :rules="emailRules"
                                    label="E-mail"
                                    required>
                                </v-text-field>
                                <vue-tel-input-vuetify
                                    dense
                                    clearable
                                    required
                                    label="Teléfono"
                                    placeholder=""
                                    mode="international"
                                    :rules=textRules
                                    :preferred-countries="['US', 'MX']"
                                    :valid-characters-only="true"
                                    v-model="contactItem.phone">
                                </vue-tel-input-vuetify>
<!--                                success-messages="Número válido!"
                                error-messages="Número de teléfono no válido!"-->
                                <v-textarea
                                    dense
                                    v-model="contactItem.message"
                                    :rules="textMessageRules"
                                    label="Mensaje"
                                    required/>
                                <v-btn
                                    :disabled="!valid"
                                    color="primary"
                                    :dark="valid"
                                    rounded
                                    block
                                    class="mt-3"
                                    @click="submit">
                                    Enviar
                                </v-btn>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar
            v-model="snackbar.enabled"
            timeout="3000"
            right
            top
            :color="snackbar.color">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar.enabled = false">
                    Información
                </v-btn>
            </template>
        </v-snackbar>
    </section>
</template>

<script>
    import mapboxgl from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import contactsService from '@/providers/ContactsService';
    import companyService from "@/providers/CompanyService";

    export default {
        props: {
            initialLocation: {
                type: Array,
                default: () => [-116.97480855890304, 32.547976944620714],
            },
            height: {
                type: String,
                default: "250px",
            },
            mapStyle: {
                type: String,
                default: "mapbox://styles/mapbox/streets-v11",
                // default: "mapbox://styles/mapbox/outdoors-v11",
            },
            color: {
                type: String,
                default: "orange",
            },
            containerID: {
                type: String,
                default: "map",
            }
        },
        data: () => ({
            map: null,
            marker: null,
            focus: false,
            valid: true,
            mapBoxApiKey: process.env.VUE_APP_MAPBOX_API_KEY,
            contactItem : {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            defaultContactItem : {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            company: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                map_lat: 0,
                map_lng: 0,
                facebook_profile: "",
                twitter_profile: "",
                instagram_profile: "",
                skype_profile: "",
                youtube_video: "",
                logo_url: ""
            },
            icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
            nameRules: [
                (v) => !!v || "El campo name es obligatorio",
                (v) => (v && v.length >= 6) || "El nombre precisa tener al menos mas de 6 caracteres",
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                (v) => !!v || "El campo email es obligatorio",
                (v) => /.+@.+\..+/.test(v) || "El E-mail precisa ser válido",
            ],
            textMessageRules: [
                (v) => !!v || "El campo de texto es obligatorio",
                (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
            ],
            lazy: false,
            snackbar: {
                enabled: false,
                text: '',
                color: ''
            }
        }),
        computed: {
            containerCSS() {
                return {
                    width: "100%",
                    height: this.height,
                };
            },
        },
        filters: {
            formatPhone(phn, country) {
                // console.log('phn:', phn);
                if (phn == 'undefined' || phn == null || phn === '') {
                    return country;
                }
                switch(country) {
                    case "US":
                        return "USA +1 (" + phn.substring(0,3) + ") " + phn.substring(3,6) + "-" + phn.substring(6);
                    case "MX":
                        return "MEX +52 (" + phn.substring(0,3) + ") " + phn.substring(3,6) + "-" + phn.substring(6);
                    default:
                        return country;
                }
            },
            formatPhoneNumber(value) {
                if (value === null) {
                    return '';
                }
                return "(" + value.substring(0,3) + ") " + value.substring(3,6) + "-" + value.substring(6);
            }
        },
        methods: {
            getCompany() {
                this.map = null;
                companyService.getViewData().then(record => {
                    // console.log("Record for Company: ", record.value);
                    // const lng = -116.97480855890304;
                    // const lat = 32.547976944620714;
                
                    // Gestion de la ubicacion en el mapa.
                    const lng = record.value.map_lng;
                    const lat = record.value.map_lat;
                    console.log('lng: ', lng);
                    console.log('lat: ', lat);
                    this.setLocationCoordinates({lng, lat});
                    this.createMap();
                
                    if (record.value !== null) {
                        this.company = record.value;
                        // console.log("company: ", this.company);
                    
                    }
                });
            },
            submit() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // Agrega el registro por el metodo POST
                    contactsService.addRecord(this.contactItem).then(result => {
                        if (result.success) {
                            this.contactItem = Object.assign({}, this.defaultContactItem);
                            this.snackbar.text = 'Datos de contacto registrado con éxito...';
                            this.snackbar.color = "success"
                        } else {
                            this.snackbar.text = 'Un error impidió guardar el Dato de Contacto requerido!';
                            this.snackbar.color = "danger"
                        }
                        this.snackbar.enabled = true;
                        this.$refs.form.reset();
                    });
                }
            },
            async createMap() {
                try {
                    mapboxgl.accessToken = this.mapBoxApiKey;
                    // Create map object
                    this.map = new mapboxgl.Map({
                        container: this.containerID,
                        style: this.mapStyle,
                        center: this.location,
                        zoom: 10,
                    });
                    // Controles basicos de navegacion: zoom y rotacion.
                    this.map.addControl(new mapboxgl.NavigationControl());
                
                    // Evento del mapa.
                    this.map.on('load', () => {
                        // console.log('here');
                        this.marker = new mapboxgl.Marker().setLngLat(this.location).addTo(this.map);
                        // console.log(this.marker);
                    });
                    // Add Click Listener
                    this.map.on("click", (event) => {
                        console.log('evento: ', event);
                        alert('Visitar el sitio oficial de MapBox');
                        // window.location.href = 'https://www.mapbox.com/';
                    });
                } catch (err) {
                    console.error("map error", err);
                }
            },
            removeMapMarkers() {
                this.marker.remove();
                const oldMarker = document.querySelector(".mapboxgl-marker");
                if (oldMarker) {
                    oldMarker.parentElement.removeChild(oldMarker);
                }
            },
            addMapMarker(lngLat) {
                console.log('lngLat: ', lngLat);
                this.marker = new mapboxgl.Marker().setLngLat(this.location).addTo(this.map);
                console.log(this.marker);
            },
            setLocationCoordinates(lngLat) {
                this.location = [lngLat.lng, lngLat.lat];
            },
            setLocation(lngLat) {
                this.removeMapMarkers();
                this.addMapMarker(lngLat);
                this.setLocationCoordinates(lngLat);
                // this.$emit("input", this.location);
            },
            requestLocation() {
                // Request to get the user's current location
                window.navigator.geolocation.getCurrentPosition((position) => {
                    // get the latitude and longitude returned
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    // set location data
                    this.setLocation({lng, lat});
                    this.map.flyTo({center: [lng, lat], zoom: 15});
                });
            },
        },
        mounted() {
            this.contactItem = Object.assign({}, this.defaultContactItem);
            this.getCompany();
        },
    }
</script>

<style scoped>
    section {
        position: relative;
        background-color: #ffffff;
        margin-top: 0;
        padding-top: 50px;
    }
    #contact {
        /*background-color: #f4f7f5;*/
        background-color: #ffffff;
    }
    .boxdivider {
        background-color: transparent;
        width: 200px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        /*border-bottom-color: blueviolet;*/
        border-bottom-color: #6c6860;
        border-bottom-style: solid;
        display: block;
    }
</style>
