<template>
    <section id="slogan" v-if="sectionItem">
        <v-container fluid class="fill-height ma-0 pa-0 text-center" v-if="sectionItem.image_url !== null">
            <v-row class="ma-0 pa-0">
                <v-img :src="sectionItem.image_url"
                       width="100%" height="350"
                       max-height="400"
                       position="top"
                       class="mx-0 px-0 align-center"
                       transition="slide-x-transition"
                       gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <div class="holder" v-wow="{
                                'animation-name': 'slideInDown',
                                'animation-duration': '1s',
                                'animation-delay': '2s'
                            }">
                                <span
                                    class="text-h5 text-md-h3 white--text text-uppercase text-light font-weight-light">
                                    {{ sectionItem.title }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="mt-0 wow animate__animated animate__slideInUp animate__slow animate__delay-2s"
                                 style="width: 100%">
                                <span class="text-caption">
                                    <p
                                        class="text-h6 text-md-h4 font-weight-light orange--text text--lighten-3 text-uppercase">
                                        {{ sectionItem.sub_title }}
                                    </p>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                                class="ma-2 yellow--text text--lighten-3"
                                outlined
                                large
                                dark
                                plain
                                @click.native="scrollToId('contact')">
                                Contáctenos
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-img>
            </v-row>
        </v-container>
        <v-container fluid class="fill-height py-10 text-center" style="width: 100%;" v-else>
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <div class="holder" v-wow="{
                        'animation-name': 'slideInDown',
                        'animation-duration': '1s',
                        'animation-delay': '2s'
                    }">
                        <span
                            class="text-h3 text-md-h2 blue--text text-uppercase text-light font-weight-light">
                            Inversiones para un futuro asegurado!
                        </span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="mt-0 wow animate__animated animate__slideInUp animate__slow animate__delay-2s"
                         style="width: 100%">
                        <span class="text-caption">
                            <p
                                class="text-h5 text-md-h4 font-weight-light black--text text--lighten-3 text-uppercase">
                                Empiece a invertir con nosotros
                            </p>
                        </span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-btn
                        class="ma-2 yellow--text text--lighten-3"
                        outlined
                        large
                        dark
                        plain
                        @click.native="scrollToId('contact')">
                        Contáctenos
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </section>

</template>

<script>
    import "@fontsource/raleway"; // Defaults to weight 400.
    import sloganService from "@/providers/SloganService";

    export default {
        name: "SloganSection",
        data: () => ({
            sectionItem: {
                id: 0,
                title: "",
                sub_title: "",
                image_url: ""
            }
        }),
        methods: {
            scrollToId(id) {
                document.getElementById(id).scrollIntoView();
                // window.scrollBy(0, -60);
                window.scrollBy({
                    top: -70,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            formatText(text){
                let arr = [];
                // text.split('\n').forEach(item=>arr.push(`<p>${item.trim()}</p>`));
                text.split('\n').forEach(item=>arr.push(`<span>${item.trim()}</span>`));
                return arr.join('<br>');
            },
            arrayTextLine(val) {
                let lines = val.replace(/\r\n/g,"\n").split("\n");
                let textArray = [];
                for (let i=0; i < lines.length; i++) {
                    if (/\S/.test(lines[i])) {
                        textArray.push(lines[i].trim(lines[i]));
                    }
                }
                // console.log('Array de elementos texts: ', textArray);
                return textArray;
            },
            getSlogan() {
                sloganService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.sectionItem = record.value;
                    }
                });
            }
        },
        mounted() {
            this.getSlogan();
        }
    }
</script>

<style scoped>
    .font-latop {
        font-family: "Lato",sans-serif;
    }
    #banner {
        z-index: 0;
    }
    section {
        position: relative;
        /*margin: 0;
        padding: 0;*/
        margin-top: 0;
        padding-top: 0;
        background-color: #ffffff;
        font-family: "Relaway",sans-serif;
    }
    .container {
        margin-top: 10px;
        padding-top: 10px;
    }
    .backcolor {
        background-color: #2d2d83;
    }
    .text-spacing {
        letter-spacing: 1em;
    }
</style>