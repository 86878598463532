<template>
    <section id="banner">
<!--        <div v-if="sliders.length >= 3">
            <v-carousel
                hide-delimiters
                show-arrows-on-hover
                :cycle="true"
                :continuous="$vuetify.breakpoint.mdAndUp"
                :interval="10000"
                :show-arrows="$vuetify.breakpoint.mdAndUp"
                height="auto"
                class="mx-0">
                <v-carousel-item v-for="item in sliders"
                                 :key="item.id"
                                 reverse-transition="fade-transition"
                                 transition="fade-transition">
                    <div class="px-0">
                        <v-img :src="item.image_url != null ? item.image_url : require('@/assets/images/no-image.jpg')"
                               height="calc(100vh)"
                               width="100%"
                               aspect-ratio="1"
                               gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)"
                               class="mx-0">
                            <v-container fluid class="header-container fill-height"
                                         style="width: 100%">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="text-center">
                                        <div class="mt-5 animate__animated animate__fadeInDown animate__delay-1s"
                                             style="width: 100%">
                                            <span class="font-unica white&#45;&#45;text text-h4 text-md-h1 text-uppercase text-light font-weight-light text-spacing">
                                                {{ item.title }}
                                            </span>
                                        </div>
                                        <div
                                            class="mt-5 wow animate__animated animate__fadeInUp animate__delay-2s">
                                            <span class="font-noticia text-h5 text-md-h3 font-weight-light yellow&#45;&#45;text text&#45;&#45;lighten-3 text-uppercase">
                                                    {{ item.sub_title }}
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            class="green&#45;&#45;text text&#45;&#45;lighten-4 ma-2 mx-10 mx-md-1 wow animate__animated animate__flipInX animate__delay-3s"
                                            outlined
                                            large
                                            rounded
                                            @click="gotoSection('#projects')"
                                            @click.native="scrollToId('projects')"
                                            v-if="item.show_link">
                                            Ver Portafolio
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </div>
                </v-carousel-item>
            </v-carousel>
        </div>-->
        <div v-if="sliders.length >= 3">
            <VueSlickCarousel v-bind="settings" style="height: 630px;">
                <div v-for="item in sliders"
                     :key="item.id"
                     class="px-0">
                    <v-img :src="item.image_url != null ? item.image_url : require('@/assets/images/no-image.jpg')"
                           height="calc(100vh)"
                           width="100%"
                           aspect-ratio="1"
                           gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)"
                           class="mx-0 animated animate__zoomIn animate__slower">
                        <v-container fluid class="header-container fill-height"
                                     style="width: 100%">
                            <v-row align="center" justify="center">
                                <v-col cols="12" class="text-center">
                                    <div class="mt-5 animate__animated animate__fadeInDown animate__delay-1s"
                                         style="width: 100%">
                                        <span class="font-unica white--text text-h4 text-md-h1 text-uppercase text-light font-weight-light text-spacing">
                                            {{ item.title }}
                                        </span>
                                    </div>
                                    <div class="mt-5 wow animate__animated animate__fadeInUp animate__delay-3s">
                                        <span class="text-caption">
                                            <p
                                                class="font-noticia text-h5 text-md-h3 font-weight-light yellow--text text--lighten-3 text-uppercase">
                                                {{ item.sub_title }}
                                            </p>
                                        </span>
                                    </div>
                                </v-col>
<!--                                <v-col cols="12" class="text-center">
                                    <v-btn
                                        class="green&#45;&#45;text text&#45;&#45;lighten-4 ma-2 mx-10 mx-md-1 wow animate__animated animate__flipInX animate__delay-3s"
                                        outlined
                                        large
                                        rounded
                                        @click="gotoSection('#projects')"
                                        @click.native="scrollToId('projects')"
                                        v-if="item.show_link">
                                        Ver Portafolio
                                    </v-btn>
                                </v-col>-->
                            </v-row>
                        </v-container>
                    </v-img>
                </div>
            </VueSlickCarousel>
        </div>
        <div v-else>
            <div>
                <VueSlickCarousel v-bind="settings" style="height: 630px;">
                    <div v-for="(item, index) in localSliders"
                         :key="index"
                         class="px-0">
                        <v-img :src="item.imageUrl"
                               height="calc(100vh)"
                               width="100%"
                               aspect-ratio="1"
                               gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)"
                               class="mx-0 animated animate__zoomIn animate__slower">
                            <v-container fluid class="header-container position-relative fill-height"
                                         style="width:100%">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" class="text-center">
<!--                                        <div class="holder mt-5" v-wow="{
                                            'animation-name': 'zoomIn',
                                            'animation-duration':'2s',
                                            'animation-delay': '1s'
                                        }">
                                            <span
                                                class="font-unica white&#45;&#45;text text-h4 text-md-h1 text-uppercase text-light font-weight-light text-spacing">
                                                {{ item.title }}
                                             </span>
                                        </div>-->
                                        <div class="mt-5 animate__animated animate__fadeInDown animate__delay-1s"
                                             style="width: 100%">
                                            <span class="font-unica white--text text-h4 text-md-h1 text-uppercase text-light font-weight-light text-spacing">
                                                {{ item.title }}
                                            </span>
                                        </div>
                                        <div class="mt-5 wow animate__animated animate__fadeInUp animate__delay-3s">
                                            <span class="text-caption">
                                                <p
                                                    class="font-noticia text-h5 text-md-h3 font-weight-light yellow--text text--lighten-3 text-uppercase">
                                                    {{ item.subtitle }}
                                                </p>
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            class="green--text text--lighten-4 ma-2 mx-10 mx-md-1 wow animate__animated animate__flipInX animate__delay-3s"
                                            outlined
                                            large
                                            rounded
                                            @click="gotoSection('#projects')"
                                            @click.native="scrollToId('projects')"
                                            v-if="item.show_link">
                                            Ver Portafolio
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </section>
</template>

<script>
    import "@fontsource/unica-one";
    import "@fontsource/noticia-text";
    import VueSlickCarousel from 'vue-slick-carousel';
    import "vue-slick-carousel/dist/vue-slick-carousel.css";
    import bannerService from '@/providers/BannerService';

    export default {
        name: 'BannerSectionComponent',
        components: { VueSlickCarousel },
        data: () => ({
            sliders: [],
            localSliders: [
                {
                    title: 'Sube en cada paso!',
                    subtitle: 'Y te llevaremos más lejos',
                    imageUrl: require('@/assets/images/sliders/slider1.jpg'),
                    showButton: true
                },
                {
                    title: 'Somos tu solución',
                    subtitle: 'Líder mundial en negocios inmobiliarios',
                    imageUrl: require('@/assets/images/sliders/slider2.jpg'),
                    showButton: false
                }
            ],
            sliderCount: 2,
            settings: {
                "fade": true,
                "dots": false,
                "arrows": false,
                "infinite": true,
                "swipe": false,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "autoplay": true,
                "speed": 2000,
                "autoplaySpeed": 8000,
                "pauseOnDotsHover": false,
                "pauseOnFocus": false,
                "pauseOnHover": false
            }
        }),
        methods: {
            getSliders() {
                // Lista de Proyectos
                bannerService.getAllRecords().then(record => {
                    console.log('Sliders: ', record.value);
                    this.sliders = record.value;
                });
            },
            gotoSection(toHash) {
                this.$router.push({path: '/homepage', hash: toHash});
            },
            scrollToId(id) {
                document.getElementById(id).scrollIntoView();
                // window.scrollBy(0, -60);
                window.scrollBy({
                    top: -60,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
        mounted() {
            this.sliders = [];
            this.getSliders();
        }
    };
</script>

<style scoped>
    .font-unica {
        font-family: "Unica One", sans-serif !important;
    }
    .font-noticia {
        font-family: "Noticia Text",serif !important;
    }
    #banner {
        z-index: 0;
    }
    section {
        position: relative;
        margin-top: 0;
        padding-top: 0;
        background-color: #ffffff;
        font-family: "Raleway",serif;
        height: calc(100vh);
    }
    .backcolor {
        background-color: #2d2d83;
    }
    .text-spacing {
        letter-spacing: 1em;
    }
    .fill-height-image {
        height: calc(100vh);
    }
</style>

// Líder mundial en negocios inmobiliarios
// Somos tu solución
// Tu asesor inmobiliario de confianza
// Vendemos sueños
// Tu profesional Inmobiliario
// Una experiencia a medida
// Hacemos de tu objetivo el nuestro
// Donde los sueños se hacen realidad
