<template>
    <section id="about">
        <div class="container-fluid fill-height py-0" style="width: 100%; height: 100%">
            <div class='quote-left'>
                <div class='quote-image' v-if="$vuetify.breakpoint.mdAndUp"></div>
                <div class='quote-circle' v-if="false">
                    <div class='quote-circle-inner'>
                        <v-icon>mdi-format-quote-close</v-icon>
                    </div>
                </div>
                <div class='quote-text'>
                    <p
                        class="font-weight-light font-italic display-2 mb-5 text-h5 text-md-h3 blue--text text--darken-3">
                        ¿Quiénes Somos?
                    </p>
                    <p>
<!--                        <v-icon dark large disabled>mdi-format-quote-open</v-icon>-->
                        PEB es una firma de capital privado que se crea con el objetivo de poder gestionar,
                        planificar y hacer crecer su patrimonio, invirtiéndolo en activos tantos financieros
                        como no financieros. Estamos formados por administradores, asesores y gestores financieros,
                        especialistas fiscales y expertos en derecho. Creemos firmemente y nos centramos con dedicación
                        exclusiva en nuestros clientes sin perder de vista los objetivos de rentabilidad/riesgo para
                        generar constantemente una rentabilidad general competitiva.
                        <v-icon dark large disabled color="darkbarcolor">mdi-format-quote-close</v-icon>
                    </p>
                    <div class='quote-author'>
<!--                        <p>- Private Equity</p>-->
                        <v-btn
                            class="ma-0 blue--text text--darken-3"
                            outlined
                            plain
                            small
                            @click="showAbout()">
                            Ver más...
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import "@fontsource/raleway";

    export default {
        name: "AboutSection",
        data: () => ({
            model: null
        }),
        methods: {
            showAbout() {
                this.$router.push({path: '/about-us'});
            }
        }
    };
</script>

<style scoped>
    section {
        position: relative;
        background-color: #f3f3f3;
        margin-top: 0;
        padding-top: 0;
    }
    #about {
        background-color: #ffffff;
     /*   padding: 0;
        margin: 0;*/
    }
    .v-btn {
        text-transform: none !important;
    }
    .boxdivider {
        background-color: transparent;
        width: 200px;
        height: 3px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        /*border-bottom-color: blueviolet;*/
        border-bottom-color: #6c6860;
        border-bottom-style: solid;
        display: block;
    }
</style>

<style scoped lang="scss">
    $light-blue: hsl(194, 49%, 73%);
    $dark-blue: hsl(221, 49%, 33%);
    $dark-green: hsl(166, 72%, 28%);
    $light-green: hsl(156, 72%, 73%);
    $gray: hsl(210, 36%, 96%);

    div.quote-left{
        position:relative;
        display:flex;
        flex-direction:row;
        // align-items:center;
        width:100%;
        min-height:500px;
        // max-height:550px;
        background-color: #f3f3f3;  // $dark-blue;
        margin-top: 0;
        margin-bottom:0;

        div.quote-image{
            width:50%;
            height:inherit;
            background-image: url("~@/assets/images/logo-slogan-Asset3@300.png");
            background-position: center;
            background-size: 350px;
            // border-radius: 16px 0px 0px 16px;
            // clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
        }
        div.quote-circle{
            position:relative;
            left: -83px;
            top: 180px;
            z-index:10;
            height:50px;
            width:50px;
            border-radius: 50%;
            background-color:white;
            color:$dark-blue;

            display:flex;
            justify-content:center;
            align-items:center;
            text-align:center;

            div.quote-circle-inner{
                height:35px;
                width:35px;
                border-radius: 50%;

                display:flex;
                justify-content:center;
                align-items:center;
                text-align:center;
                box-shadow:0px 0px 10px $dark-blue;
            }
        }

        div.quote-text{
            flex:1;
            padding:80px 30px 100px 30px;
            // border-radius: 0px 16px 16px 0px;
            color: #191919;
            font-family: "Raleway", sans-serif;
            font-style:normal;
            font-size:22px;
            font-weight:lighter;
            line-height:30px;

            div.quote-author{
                text-align:right;
                font-size:20px;
                font-weight:500;
                font-style:normal;
                color:$light-blue;
            }
        }
    }
</style>