<template>
    <section id="values" v-if="sectionItem">
        <div style="width: 100%" v-if="$vuetify.breakpoint.mdAndUp">
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="start">
                    <v-col cols="12" md="6">
                        <v-img
                            height="auto"
                            max-height="350"
                            :width="$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.width-20 : '100%'"
                            position="top"
                            contain
                            :src="sectionItem.image_url != null ? sectionItem.image_url : require('@/assets/images/no-image.jpg')"
                            transition="slide-x-transition"
                            class="rounded-lg">
                        </v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-container fluid class="text-left pr-10">
                            <v-row justify="start">
                                <v-col cols="12">
                                    <span
                                        class="font-raleway text-h5 text-md-h4 font-weight-regular text-uppercase">
                                        {{ sectionItem.title }}
                                    </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-responsive class="pt-1"
                                                  :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                        <span class="font-lato text-body-1 black--text"
                                              v-html="formatText(sectionItem.content)">
                                        </span>
                                    </v-responsive>
<!--                                    <v-responsive class="font-lato text-body-1 font-weight-regular black&#45;&#45;text"
                                                  :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                        <ul>
                                            <span v-html="formatList(sectionItem.content)">
                                            </span>
                                        </ul>
                                    </v-responsive>-->
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div style="width: 100%" v-else>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="start">
                    <v-col cols="12" md="6">
                        <v-container fluid class="text-left pl-10 pr-10">
                            <v-row justify="start">
                                <v-col cols="12">
                                    <span
                                        class="font-raleway text-h5 text-md-h5 font-weight-bold text-uppercase">
                                        {{ sectionItem.title }}
                                    </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-responsive class="pt-1"
                                                  :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                        <span class="font-lato text-body-1 black--text"
                                              v-html="formatText(sectionItem.content)">
                                        </span>
                                    </v-responsive>
<!--                                    <v-responsive class="font-lato text-body-1 font-weight-regular black&#45;&#45;text"
                                                  :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                        <ul>
                                            <span v-html="formatText(sectionItem.content)">
                                            </span>
                                        </ul>
                                    </v-responsive>-->
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img
                            height="auto"
                            max-height="350"
                            :width="$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.width-20 : '100%'"
                            position="top"
                            contain
                            :src="sectionItem.image_url != null ? sectionItem.image_url : require('@/assets/images/no-image.jpg')"
                            transition="slide-x-transition"
                            class="rounded-lg">
                        </v-img>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </section>
</template>

<script>
    import "@fontsource/raleway";
    import "@fontsource/lato";
    import valuesService from "@/providers/ValuesService";

    export default {
        name: "ValuesSection",
        data: () => ({
            sectionItem: {
                id: 0,
                title: "",
                content: "",
                image_url: ""
            }
        }),
        methods: {
            formatText(text){
                let arr = [];
                // text.split('\n').forEach(item=>arr.push(`<p>${item.trim()}</p>`));
                text.split('\n').forEach(item=>arr.push(`<span>${item.trim()}</span>`));
                return arr.join('<br>');
            },
            formatList(text){
                let arr = [];
                text.split('\n').forEach(item=>arr.push(`<li>${item.trim()}</li>`));
                return arr.join('');
            },
            arrayTextLine(val) {
                let lines = val.replace(/\r\n/g,"\n").split("\n");
                let textArray = [];
                for (let i=0; i < lines.length; i++) {
                    if (/\S/.test(lines[i])) {
                        textArray.push(lines[i].trim(lines[i]));
                    }
                }
                // console.log('Array de elementos texts: ', textArray);
                return textArray;
            },
            getMision() {
                valuesService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.sectionItem = record.value;
                    }
                });
            }
        },
        mounted() {
            this.getMision();
        }
    }
</script>

<style scoped>
    section {
        position: relative;
        background-color: #ffffff;
        margin-top: 0;
        padding-top: 0;
    }
    .font-raleway {
        font-family: "Raleway", sans-serif !important;
    }
    .font-lato {
        font-family: "Lato", sans-serif !important;
    }
    #values {
        background-color: #ffffff;
        /*   padding: 0;
           margin: 0;*/
    }
    .v-btn {
        text-transform: none !important;
    }
    .bottom-gradient {
        background-image: linear-gradient(
            to bottom,
            rgba(163, 153, 248, 0.295) 0%,
            rgba(101, 99, 248, 0.699) 100%
        );
    }
    .blend-opacity {
        opacity: 0.1;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        /*border-bottom-color: blueviolet;*/
        border-bottom-color: #ffc107;
        border-bottom-style: solid;
        display: block;
    }
</style>