<template>
    <section id="paralax">
        <v-parallax
            dark
            src="@/assets/images/complejo-departamentos-modernos-bw.jpg"
            height="800"
            class="fill-height container--fluid">
            <v-container fluid class="fill-height container--fluid">
                <v-row
                    align="center"
                    justify="center">
                    <v-col cols="12" class="text-center">
                        <span class="text-h4 text-md-h2 yellow--text text--lighten-4 font-weight-light mb-4">
                            Invierta con Nosotros
                        </span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn
                            class="ma-2 yellow--text text--lighten-3"
                            outlined
                            large
                            dark
                            plain
                            @click.native="scrollToId('contact')">
                            Contáctenos
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-parallax>
    </section>
</template>

<script>
    export default {
        name: "ParalaxCompanySection",
        methods: {
            scrollToId(id) {
                document.getElementById(id).scrollIntoView();
                // window.scrollBy(0, -60);
                window.scrollBy({
                    top: -60,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>

<style scoped>
    section {
        position: relative;
        background-color: #ffffff;
        margin-top: 0px;
        padding-top: 0px;
    }
    .bottom-gradient {
        background-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0.185) 20%,
        rgba(0, 0, 0, 0.356) 80%);
    }
</style>